import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import theme from '../../../theme';

const { spacing } = theme;

function Heading({ HeadingT, SubHeadingT, MiniTextT, naked }) {
  const MtContainer = styled.div`
    margin-top: ${spacing['1x']};
  `;
  return (
    <Flex
      width="100%"
      p={naked && `${spacing['2x']} ${spacing['3x']}`}
      flexDirection="column"
    >
      <Flex
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        {HeadingT}
        {MiniTextT}
      </Flex>
      <MtContainer>{SubHeadingT}</MtContainer>
    </Flex>
  );
}

Heading.propTypes = {
  HeadingT: PropTypes.node.isRequired,
  SubHeadingT: PropTypes.node,
  MiniTextT: PropTypes.node,
  naked: PropTypes.bool.isRequired,
};

Heading.defaultProps = {
  SubHeadingT: null,
  MiniTextT: null,
};

export default Heading;
