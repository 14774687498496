import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { navigatorContext } from '../components/navigator';
import TextField from '../components/textfield';

import { ReactComponent as TickIcon } from '../icons/tick.svg';

const HFlex = styled(Flex)`
  height: 100%;
`;

function TextFieldPage() {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  const [val, setVal] = React.useState('');
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Text Fields');
  }, []);

  return (
    <HFlex width="100%" p="0px 24px" flexDirection="column">
      <Flex m="10px" />
      <TextField
        title="Title"
        errorString="There seems to be an error"
        placeholder="Type Something"
        statusIcon={<TickIcon />}
        error={val.length >= 12}
        success={val.length > 6 && val.length < 12}
        onChange={setVal}
      />
    </HFlex>
  );
}

export default TextFieldPage;
