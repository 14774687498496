import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { CaptionRegular } from '../../typography/weights';

const Flexheight = styled(Flex)`
  height: 32px;
  align-items: center;
`;

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function MessageBase({ message, RightComp, bg, color }) {
  return (
    <Flex width="100%" p="0 24px" bg={bg} alignItems="center">
      <Flexheight width="100%" mr={RightComp ? '24px' : 0} mt="8px" mb="8px">
        <Ellipsis>
          <CaptionRegular color={color}>{message}</CaptionRegular>
        </Ellipsis>
      </Flexheight>
      {RightComp}
    </Flex>
  );
}

MessageBase.propTypes = {
  message: PropTypes.string.isRequired,
  RightComp: PropTypes.node,
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

MessageBase.defaultProps = {
  RightComp: null,
};

export default MessageBase;
