import React from 'react';
import { Flex, Image } from 'rebass';
import styled from 'styled-components';

// Components
import { navigatorContext } from '../components/navigator';
import PropertyHeading from '../components/propertyheading';
import PropertyCard from '../components/propertycard';
import UserCard from '../components/usercard';
import AmenityCard from '../components/amenitycard';
import { PlainSmall, TextGreenSmall } from '../components/buttons/sizes/small';
import { TextBlackLarge } from '../components/buttons/sizes/large';
import { InvertedHeading, Heading1614 } from '../components/heading/types';
import { SubtitleMedium, Body2Regular } from '../components/typography/weights';
import { SingleLine1612, SingleLine1812 } from '../components/singlelinetext';
import ProperlyTag from '../components/tags/type/properly';
import theme from '../theme';

// Icons
import { ReactComponent as BackIcon } from '../icons/back.svg';
import { ReactComponent as HeartIcon } from '../icons/heartfilled.svg';
import { ReactComponent as HeartEmpty } from '../icons/heart.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as Boutique } from '../icons/boutiquestay.svg';
import { ReactComponent as GuestIcon } from '../icons/guest.svg';
import { ReactComponent as RoomIcon } from '../icons/room.svg';
import { ReactComponent as PrivateIcon } from '../icons/privateroom.svg';
import { ReactComponent as PetIcon } from '../icons/pet.svg';
import { ReactComponent as BellBoyIcon } from '../icons/bellboy.svg';
import { ReactComponent as CareTaker } from '../icons/caretaker.svg';
import { ReactComponent as Family } from '../icons/family.svg';
import { ReactComponent as Power } from '../icons/power.svg';
import { ReactComponent as Arrow } from '../icons/arrow.svg';
import { ReactComponent as Forward } from '../icons/forward.svg';

// Images
import PropertyImage from './propertydemoassets/property.png';
import Gallery1 from './propertydemoassets/galleryimages/gallery1.png';
import Gallery2 from './propertydemoassets/galleryimages/gallery2.png';
import Property1 from './propertydemoassets/propertyimages/1.jpg';
import Property2 from './propertydemoassets/propertyimages/2.jpg';
import Property3 from './propertydemoassets/propertyimages/3.jpg';
import MapImage from './propertydemoassets/map.png';
import Boy from './propertydemoassets/boy.png';
import Saadhvi from './propertydemoassets/saadhvi.png';

const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  img {
    width: 100%;
  }
`;

const Card = styled(Flex)`
  border: 1px solid rgb(237, 237, 237);
  background-color: ${theme.applicationColors.fillColors.solidWhite};
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(223, 223, 223, 0.15);
`;

const TagContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 100px;
`;

const IconContainer = styled(Flex)`
  path {
    fill: #fff;
  }
  use {
    fill: #fff;
  }
`;

const Content = styled(Flex)`
  div {
    line-height: 25px;
  }

  button {
    padding-top: 0px !important;
  }
`;

const Slider = styled(Flex)`
  overflow-x: scroll;

  img {
    height: 133px;
    width: 204px;
    margin-right: 8px;
  }
`;

const PropertySlider = styled(Flex)`
  overflow-x: scroll;
`;

// eslint-disable-next-line react/prop-types
const PropertyPreviewDemo = ({ history }) => {
  const {
    showNavigator,
    setRenderLeft,
    setRenderRight1,
    setRenderRight2,
    setHeading,
  } = React.useContext(navigatorContext);

  const [faved, setFaved] = React.useState(false);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleFaved = () => {
    setFaved(!faved);
  };

  React.useEffect(() => {
    showNavigator(true);
    setHeading('');
    setRenderLeft(
      <IconContainer p="10px" width="44px" onClick={handleBackClick}>
        <BackIcon />
      </IconContainer>,
    );
    setRenderRight2(
      <IconContainer p="10px" width="44px">
        <ShareIcon />
      </IconContainer>,
    );
  }, []);

  React.useEffect(() => {
    setRenderRight1(
      faved ? (
        <Flex p="10px" width="44px" onClick={handleFaved}>
          <HeartIcon />
        </Flex>
      ) : (
        <IconContainer p="10px" width="44px" onClick={handleFaved}>
          <HeartEmpty />
        </IconContainer>
      ),
    );
  }, [faved]);

  return (
    <Flex flexDirection="column">
      <Banner>
        <Image src={PropertyImage} />
      </Banner>
      <Flex mt="288px" mr="24px" alignSelf="flex-end">
        <PlainSmall>View all images</PlainSmall>
      </Flex>
      <Card mt="16px" mx="8px">
        <PropertyHeading
          headingText="Entire 5 bedroom villa with unique view"
          subHeadingText="Manali, Himachal Pradesh"
          ratingValue="3.0"
          reviewText="(7 reviews)"
        />
      </Card>
      <Flex
        mt="16px"
        flexDirection="row"
        px="24px"
        py="16px"
        alignItems="center"
        justifyContent="space-between"
        style={{ position: 'relative' }}
      >
        <InvertedHeading
          headingText="Hosted By"
          subHeadingText="Sangeeta Verma"
        />
        <TagContainer>
          <ProperlyTag tailLess>Managed by Properly</ProperlyTag>
        </TagContainer>
        <Image src={Saadhvi} width="44px" height="44px" borderRadius={50} />
      </Flex>
      <Flex mt="16px" px="24px" py="16px">
        <SubtitleMedium>The Space</SubtitleMedium>
      </Flex>
      <Flex flexDirection="column">
        <SingleLine1612
          leftIcon={<Boutique />}
          headingText="Villa"
          subtitleText="Contemporary vibe and a quirky, modern spirit"
          borderColor="none"
        />
        <SingleLine1612
          leftIcon={<PrivateIcon />}
          headingText="1 Private Room"
          borderColor="none"
        />
        <SingleLine1612
          leftIcon={<RoomIcon />}
          headingText="1 BedRoom"
          borderColor="none"
        />
        <SingleLine1612
          leftIcon={<GuestIcon />}
          headingText="3 extra guests"
          borderColor="none"
        />
      </Flex>
      <Flex flexDirection="column" mt="32px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>About the property</SubtitleMedium>
        </Flex>
        <Content mx="24px">
          <Body2Regular>
            Each Tree House nest is christened after a bird found in the area.
            Christened after various bird species, these spacious nests offer a
            unique tree house experience with live tree branches running through
            it. Fitted with all mod cons, and an earthen decor, makes sure that
            it… &nbsp;
            <TextGreenSmall onClick={() => history.push('/about')}>
              read more
            </TextGreenSmall>
          </Body2Regular>
        </Content>
      </Flex>
      <Flex flexDirection="column" mt="32px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>Amenities</SubtitleMedium>
        </Flex>
        <Flex flexWrap="wrap" px="24px" width="100%" alignItems="space-between">
          <Flex mb="16px" width="50%">
            <AmenityCard text="Pet Care" imgSrc={<PetIcon />} />
          </Flex>
          <Flex mb="16px" width="50%">
            <AmenityCard text="Bell Boy" imgSrc={<BellBoyIcon />} />
          </Flex>
          <Flex mb="16px" width="50%">
            <AmenityCard text="Family" imgSrc={<Family />} />
          </Flex>
          <Flex mb="16px" width="50%">
            <AmenityCard text="Care Taker" imgSrc={<CareTaker />} />
          </Flex>
          <Flex mb="16px" width="50%">
            <AmenityCard text="Power Backup" imgSrc={<Power />} />
          </Flex>
        </Flex>
        <Flex ml="24px" alignItems="center">
          <TextBlackLarge onClick={() => history.push('/aminities')}>
            <Flex flexDirection="row" alignItems="center">
              View all 16 amenities
              <Flex ml="8px" mb="1px">
                <Arrow />
              </Flex>
            </Flex>
          </TextBlackLarge>
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="28px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>Gallery</SubtitleMedium>
        </Flex>
        <Slider mb="16px">
          <Flex px="12px" />
          <Image src={Gallery1} />
          <Image src={Gallery2} />
          <Image src={Property1} />
          <Image src={Property2} />
          <Flex px="12px" />
        </Slider>
        <Flex ml="24px" alignItems="center">
          <TextBlackLarge>
            <Flex flexDirection="row" alignItems="center">
              View all 25 images
              <Flex ml="8px" mb="1px">
                <Arrow />
              </Flex>
            </Flex>
          </TextBlackLarge>
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="38px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>Location</SubtitleMedium>
        </Flex>
        <Flex mx="24px" mb="16px">
          <Image src={MapImage} height="189px" width="100%" />
        </Flex>
        <Flex ml="24px">
          <Heading1614
            headingText="Mall Road, Manali"
            subHeadingText="3.8km from Bus stand"
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="32px">
        <Flex mb="24px">
          <SingleLine1812
            headingText="How to reach?"
            rightIcon={<Forward />}
            borderColor="none"
          />
        </Flex>
        <Flex mb="24px">
          <SingleLine1812
            headingText="Available Payment Options"
            rightIcon={<Forward />}
            borderColor="none"
          />
        </Flex>
        <Flex mb="24px">
          <SingleLine1812
            headingText="Pricing Information"
            rightIcon={<Forward />}
            borderColor="none"
          />
        </Flex>
        <Flex mb="24px">
          <SingleLine1812
            headingText="Cancellation and Refund Policy"
            rightIcon={<Forward />}
            borderColor="none"
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="32px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>Traveller Reviews</SubtitleMedium>
        </Flex>
        <Flex>
          <UserCard
            headingText="Shailesh Verma"
            subHeadingText="3 days ago"
            imgSrc={Boy}
            ratingValue="3.0"
          />
        </Flex>
        <Content mx="24px" mt="8px" flexDirection="column">
          <Body2Regular>
            A cottage surrounded by the Kilbury forest, this place is ideal for
            nature lovers. This property provides a 360 degree view of the
            mighty Himalayan range and all the rooms have an amazing view and
            awesome breakf… &nbsp;
            <TextGreenSmall>read more</TextGreenSmall>
          </Body2Regular>
        </Content>
        <Flex ml="24px" alignItems="center">
          <TextBlackLarge>
            <Flex flexDirection="row" alignItems="center">
              View all 7 reviews
              <Flex ml="8px" mb="1px">
                <Arrow />
              </Flex>
            </Flex>
          </TextBlackLarge>
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="40px" mb="90px">
        <Flex px="24px" py="16px">
          <SubtitleMedium>Similiar Properties</SubtitleMedium>
        </Flex>
        <PropertySlider mb="16px" mt="8px">
          <Flex px="12px" />
          <PropertyCard
            headingText="Entire 5-bedroom villa"
            subHeadingText="Ahmedabad, Gujarat"
            imgSrc={Property1}
            ratingValue="4.5"
          />
          <PropertyCard
            headingText="Entire 2-bedroom apartment"
            subHeadingText="Bikaner, Rajasthant"
            imgSrc={Property2}
            ratingValue="3.5"
          />
          <PropertyCard
            headingText="Entire 4-bedroom cottage"
            subHeadingText="Jodhpur, Rajasthan"
            imgSrc={Property3}
            ratingValue="5.0"
          />
          <Flex px="12px" />
        </PropertySlider>
      </Flex>
    </Flex>
  );
};

export default PropertyPreviewDemo;
