import React from 'react';
import PropTypes from 'prop-types';
import MessageBtn from '../types/messageBtn';
import MessageIcon from '../types/messageIcon';
import theme from '../../../theme';

const constants = {
  bg: theme.applicationColors.fillColors.applicationDestructive,
  color: theme.applicationColors.textColors.textWhite,
};

export function ErrorMessageBtn({ message, btnText }) {
  return <MessageBtn message={message} btnText={btnText} {...constants} />;
}

export function ErrorMessageIcon({ message, svg }) {
  return <MessageIcon message={message} svg={svg} {...constants} />;
}

ErrorMessageBtn.propTypes = {
  message: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};
ErrorMessageBtn.defaultProps = {
  btnText: null,
};

ErrorMessageIcon.propTypes = {
  message: PropTypes.string.isRequired,
  svg: PropTypes.node,
};
ErrorMessageIcon.defaultProps = {
  svg: null,
};
