import React from 'react';
import { Flex } from 'rebass';

import { navigatorContext } from '../components/navigator';
import {
  H1Regular,
  H1SemiBold,
  H1Medium,
  H2Regular,
  H2SemiBold,
  H2Medium,
  SubtitleMedium,
  SubtitleRegular,
  SubtitleSemiBold,
  Body1Regular,
  Body1Medium,
  Body1SemiBold,
  Body2Medium,
  Body2Regular,
  Body2SemiBold,
  CaptionRegular,
  CaptionMedium,
  CaptionSemiBold,
  MiniRegular,
  MiniMedium,
  MiniSemiBold,
  OverlineRegular,
  OverlineMedium,
  OverlineSemiBold,
} from '../components/typography/weights';

function TypoGraphyPage() {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Typography');
  }, []);

  return (
    <Flex flexDirection="column" flexWrap="wrap">
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <H1SemiBold>H1 SemiBold</H1SemiBold>
        <H1Medium>H1 Medium</H1Medium>
        <H1Regular>H1 Regular</H1Regular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <H2SemiBold>H2 SemiBold</H2SemiBold>
        <H2Medium>H2 Medium</H2Medium>
        <H2Regular>H2 Regular</H2Regular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <SubtitleSemiBold>Subtitle SemiBold</SubtitleSemiBold>
        <SubtitleMedium>Subtitle Medium</SubtitleMedium>
        <SubtitleRegular>Subtitle Regular</SubtitleRegular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <Body1SemiBold>Body1 SemiBold</Body1SemiBold>
        <Body1Medium>Body1 Medium</Body1Medium>
        <Body1Regular>Body1 Regular</Body1Regular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <Body2SemiBold>Body2 SemiBold</Body2SemiBold>
        <Body2Medium>Body2 Medium</Body2Medium>
        <Body2Regular>Body2 Regular</Body2Regular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <CaptionSemiBold>Caption SemiBold</CaptionSemiBold>
        <CaptionMedium>Caption Medium</CaptionMedium>
        <CaptionRegular>Caption Regular</CaptionRegular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <MiniSemiBold>Mini SemiBold</MiniSemiBold>
        <MiniMedium>Mini Medium</MiniMedium>
        <MiniRegular>Mini Regular</MiniRegular>
      </Flex>
      <Flex mb="50px" alignItems="center" flexDirection="column">
        <OverlineSemiBold>Overline SemiBold</OverlineSemiBold>
        <OverlineMedium>Overline Medium</OverlineMedium>
        <OverlineRegular>Overline Regular</OverlineRegular>
      </Flex>
    </Flex>
  );
}

export default TypoGraphyPage;
