import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { HeadingRegular } from '../heading/types';
import { Body2SemiBold, CaptionMedium } from '../typography/weights';
import StarRating from '../rating/rating';
import theme from '../../theme';

const { spacing } = theme;

function PropertyCard({
  headingText,
  subHeadingText,
  ratingValue,
  reviewText,
}) {
  return (
    <Flex flexDirection="column" px={spacing['2x']} py={spacing['3x']}>
      <Flex mb={spacing['3x']}>
        <HeadingRegular
          headingText={headingText}
          subHeadingText={subHeadingText}
        />
      </Flex>
      <Flex flexDirection="row" alignItems="center">
        <StarRating disable ratingValue={ratingValue} />
        <Flex ml={spacing['1x']}>
          <Body2SemiBold>{ratingValue}</Body2SemiBold>
        </Flex>
        <Flex ml={spacing['1x']}>
          <CaptionMedium
            color={theme.applicationColors.textColors.textSupporter}
          >
            {reviewText}
          </CaptionMedium>
        </Flex>
      </Flex>
    </Flex>
  );
}

PropertyCard.propTypes = {
  headingText: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
  reviewText: PropTypes.string.isRequired,
};

export default PropertyCard;
