import withWeight from './withWeight';
import theme from '../../../theme';
import {
  H1,
  H2,
  Body1,
  Body2,
  Subtitle,
  Caption,
  Mini,
  Overline,
} from '../types';

export const H1Regular = withWeight(H1, theme.typography.weights.regular);
export const H1Medium = withWeight(H1, theme.typography.weights.medium);
export const H1SemiBold = withWeight(H1, theme.typography.weights.semiBold);

export const H2Regular = withWeight(H2, theme.typography.weights.regular);
export const H2Medium = withWeight(H2, theme.typography.weights.medium);
export const H2SemiBold = withWeight(H2, theme.typography.weights.semiBold);

export const Body1Regular = withWeight(Body1, theme.typography.weights.regular);
export const Body1Medium = withWeight(Body1, theme.typography.weights.medium);
export const Body1SemiBold = withWeight(
  Body1,
  theme.typography.weights.semiBold,
);

export const Body2Regular = withWeight(Body2, theme.typography.weights.regular);
export const Body2Medium = withWeight(Body2, theme.typography.weights.medium);
export const Body2SemiBold = withWeight(
  Body2,
  theme.typography.weights.semiBold,
);

export const SubtitleRegular = withWeight(
  Subtitle,
  theme.typography.weights.regular,
);
export const SubtitleMedium = withWeight(
  Subtitle,
  theme.typography.weights.medium,
);
export const SubtitleSemiBold = withWeight(
  Subtitle,
  theme.typography.weights.semiBold,
);

export const CaptionRegular = withWeight(
  Caption,
  theme.typography.weights.regular,
);
export const CaptionMedium = withWeight(
  Caption,
  theme.typography.weights.medium,
);
export const CaptionSemiBold = withWeight(
  Caption,
  theme.typography.weights.semiBold,
);

export const MiniRegular = withWeight(Mini, theme.typography.weights.regular);
export const MiniMedium = withWeight(Mini, theme.typography.weights.medium);
export const MiniSemiBold = withWeight(Mini, theme.typography.weights.semiBold);

export const OverlineRegular = withWeight(
  Overline,
  theme.typography.weights.regular,
);
export const OverlineMedium = withWeight(
  Overline,
  theme.typography.weights.medium,
);
export const OverlineSemiBold = withWeight(
  Overline,
  theme.typography.weights.semiBold,
);
