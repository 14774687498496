import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Tag from '../base/tag';

const constants = {
  background: theme.applicationColors.fillColors.properlyBlue,
  color: theme.applicationColors.textColors.textWhite,
};

function ProperlyBlue({ children, ...props }) {
  return (
    <Tag {...constants} {...props}>
      {children}
    </Tag>
  );
}

ProperlyBlue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default ProperlyBlue;
