import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Body2SemiBold, OverlineMedium } from '../typography/weights';
import theme from '../../theme';
import { ReactComponent as ClearIcon } from '../../icons/clearicon.svg';

/*eslint-disable */
const Input = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0;
  border-bottom: 1px solid
    ${p =>
      p.error
        ? theme.applicationColors.dividerColors.dividerTertiary
        : p.success
        ? theme.applicationColors.dividerColors.dividerSecondary
        : p.focused
        ? theme.applicationColors.dividerColors.dividerPrimaryDark
        : theme.applicationColors.dividerColors.dividerPrimary};
  font-family: 'Metropolis-regular';
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0px;
  position: relative;
  padding-right: ${p => p.addMargin && '20px'};
  text-overflow:ellipsis
`;

const IconContainer = styled(Flex)`
  position: absolute;
  right: 0;
  z-index: 1;
  top: 29px;
  height: 24px;
`;

const InputContainer = styled(Flex)`
  position: relative;
  height: 75px;
`;

function TextField({
  placeholder,
  errorString,
  error,
  title,
  success,
  statusIcon,
  onChange,
}) {
  const [text, setText] = React.useState('');
  const [isFocused, setFocused] = React.useState(false);
  const inputRef = React.useRef(null);
  const interval = React.useRef(false);

  const handleClickLogic = () => {
    setText('');
    onChange('');
    interval.current = true;
    setTimeout(() => {
      inputRef.current.focus();
      interval.current = false;
    });
  }

  return (
    <InputContainer flexDirection="column">
      <Body2SemiBold>{title}</Body2SemiBold>
      <Input
        placeholder={placeholder}
        error={error}
        success={success}
        value={text}
        focused={isFocused}
        addMargin={isFocused || success}
        onChange={e => {
          setText(e.target.value);
          onChange(e.target.value);
        }}
        ref={inputRef}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
      {error && (
        <OverlineMedium color={theme.applicationColors.textColors.textRed}>
          {errorString}
        </OverlineMedium>
      )}
      {text.length > 0 && isFocused && (
        <IconContainer
          alignItems="center"
          onMouseDown={handleClickLogic}
          onTouchStart={handleClickLogic}
        >
          <ClearIcon />
        </IconContainer>
      )}
      {!isFocused && success && !interval.current && (
        <IconContainer>{statusIcon}</IconContainer>
      )}
    </InputContainer>
  );
}

TextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  errorString: PropTypes.string.isRequired,
  error: PropTypes.bool,
  statusIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  success: PropTypes.bool,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  error: false,
  success: false,
  statusIcon: null,
  onChange: () => {},
};

export default TextField;
