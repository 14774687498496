import React from 'react';
import PropTypes from 'prop-types';

function withWeight(Component, fontFamily) {
  function Child({ color, innerRef, children }) {
    return (
      <Component fontFamily={fontFamily} color={color} innerRef={innerRef}>
        {children}
      </Component>
    );
  }

  Child.propTypes = {
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired, //eslint-disable-line
    color: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
      .isRequired,
  };

  return Child;
}

export default withWeight;
