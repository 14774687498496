import React from 'react';
import PropTypes from 'prop-types';
import Text from '../base';

function withSize(fontSize, lineHeight) {
  function Child({ fontFamily, color, children, innerRef }) {
    return (
      <Text
        fontFamily={fontFamily}
        color={color}
        lineHeight={lineHeight}
        fontSize={fontSize}
        ref={innerRef}
      >
        {children}
      </Text>
    );
  }

  Child.propTypes = {
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired, //eslint-disable-line
    fontFamily: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
      .isRequired,
  };

  return Child;
}

export default withSize;
