import React from 'react';
import PropTypes from 'prop-types';
import Rheostat from 'rheostat';
import 'rheostat/initialize';
import styled from 'styled-components';
import theme from '../../theme';
import { H2Regular } from '../typography/weights';

const SliderStyle = styled.div`
  width: 100%;
  padding: 0 20px;
  .value-holder {
    margin-bottom: 20px;
    div {
      display: inline-block;
      padding: 0 5px;
    }
  }
  .DefaultProgressBar_progressBar {
    background-color: ${theme.applicationColors.fillColors.applicationPrimary};
    position: absolute;
  }
  .DefaultProgressBar_background__horizontal {
    height: 4px;
    top: 0px;
  }
  .DefaultHandle_handle {
    width: 28px;
    height: 28px;
    background-color: ${theme.applicationColors.fillColors
      .applicationPrimaryDark};
    border-radius: 50%;
    outline: none;
    z-index: 2;
    border: none !important;
  }

  .DefaultHandle_handle__horizontal {
    margin-left: -14px;
    top: -12px;
  }
  .DefaultHandle_handle__disabled {
    border-color: #dbdbdb;
  }
  .DefaultBackground {
    background-color: ${theme.applicationColors.fillColors.greySupporter};
    height: 4px;
    width: 100%;
    position: relative;
    border-radius: 3px;
  }
  .rheostat {
    position: relative;
    overflow: visible;
  }
  @media (min-width: 1128px) {
    .autoAdjustVerticalPosition {
      top: 12px;
    }
  }
  .handleContainer {
    height: 4px;
    width: 100%;
    position: absolute;
    top: 0;
  }
`;

class PriceSlider extends React.Component {
  static propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  };

  valuesUpdated = ([min, max]) => {
    this.minVal.innerHTML = `₹ ${min}`;
    this.maxVal.innerHTML = `₹ ${max}`;
  };

  render() {
    const { min, max } = this.props;

    return (
      <SliderStyle>
        <div className="value-holder">
          <H2Regular
            innerRef={r => {
              this.minVal = r;
            }}
          >
            ₹{min}
          </H2Regular>
          <H2Regular> - </H2Regular>
          <H2Regular
            innerRef={r => {
              this.maxVal = r;
            }}
          >
            ₹{max}
          </H2Regular>
        </div>
        <Rheostat
          min={min}
          max={max}
          values={[min, max]}
          onValuesUpdated={({ values }) => this.valuesUpdated(values)}
        />
      </SliderStyle>
    );
  }
}
export default PriceSlider;
