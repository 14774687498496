import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Button from '../base/button';

const constants = {
  bg: 'transparent',
  border: `1px solid ${theme.applicationColors.outlineColors.outlineSecondary}`,
  color: theme.applicationColors.textColors.textPrimary,
  activeColor: theme.applicationColors.fillColors.solidWhite,
};

function Secondary({ width, height, onClick, fontSize, disabled, children }) {
  return (
    <Button
      width={width}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      {...constants}
    >
      {children}
    </Button>
  );
}

Secondary.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  fontSize: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Secondary;
