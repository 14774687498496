import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { useSpring, animated } from 'react-spring';

import { TextBlackSmall } from '../buttons/sizes/small';
import theme from '../../theme';
import { ReactComponent as SearchIcon } from '../../icons/smallsearch.svg';
import { ReactComponent as ClearIcon } from '../../icons/clearicon.svg';

const SearchFieldContainner = styled(Flex)`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Icon = styled(animated.div)`
  position: absolute;
  top: 30%;
  left: 16px;
`;

const Input = animated(styled.input`
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: Metropolis-medium;
  padding: 8px 20px 8px 44px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: ${p => p.pl}px;
  background: ${theme.applicationColors.fillColors.solidWhite};
  caret-color: ${theme.applicationColors.textColors.textGreen};
  border: 1px solid rgba(223, 223, 223, 0.5);
  box-shadow: none;
  height: 48px;
  outline: none;
  appearance: none;
`);

const SearchButton = styled(animated.div)`
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled(Flex)`
  position: absolute;
  right: 5px;
  z-index: 1;
  height: 24px;
  min-width: 24px;
  top: 12px;
`;

function SearchField({ height, value, onChange, onAction }) {
  const [focused, setFocused] = React.useState(false);
  const [text, setText] = React.useState(value);
  const inputRef = React.useRef(null);

  // Animations
  const IconTransition = useSpring({ opacity: focused ? 0 : 1 });
  const InputTransition = useSpring({
    width: focused ? '80%' : '100%',
    paddingLeft: focused ? '16px' : '44px',
  });
  const ButtonTransition = useSpring({
    right: focused ? '0px' : '-20%',
    position: 'absolute',
  });

  // handlers
  const handleClickLogic = () => {
    setText('');
    setTimeout(() => {
      inputRef.current.focus();
    });
  };

  const handleInputChange = val => {
    setText(val);
    onChange(val);
  };

  return (
    <SearchFieldContainner>
      <animated.div
        style={{ width: InputTransition.width, position: 'relative' }}
      >
        <Icon style={IconTransition}>
          <SearchIcon />
        </Icon>
        <Input
          style={{ paddingLeft: InputTransition.paddingLeft }}
          height={height}
          ref={inputRef}
          value={text}
          onChange={e => handleInputChange(e.target.value)}
          placeholder="Search"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {focused && (
          <IconContainer
            alignItems="center"
            justifyContent="center"
            onMouseDown={handleClickLogic}
            onTouchStart={handleClickLogic}
          >
            <ClearIcon />
          </IconContainer>
        )}
      </animated.div>
      <SearchButton
        style={ButtonTransition}
        onMouseDown={onAction}
        onTouchStart={onAction}
      >
        <TextBlackSmall>Cancel</TextBlackSmall>
      </SearchButton>
    </SearchFieldContainner>
  );
}

SearchField.propTypes = {
  height: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onAction: PropTypes.func,
};

SearchField.defaultProps = {
  onChange: () => {},
  onAction: () => {},
};

export default SearchField;
