import { createGlobalStyle } from 'styled-components';
import MetroPolisLight from '../components/typography/fonts/Metropolis-Light.otf';
import MetroPolisRegular from '../components/typography/fonts/Metropolis-Regular.otf';
import MetroPolisMedium from '../components/typography/fonts/Metropolis-Medium.otf';
import MetroPolisSemiBold from '../components/typography/fonts/Metropolis-SemiBold.otf';
import MetroPolisBold from '../components/typography/fonts/Metropolis-Bold.otf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Metropolis-light';
    src: url(${MetroPolisLight}) format('opentype');
  }
  @font-face {
    font-family: 'Metropolis-regular';
    src: url(${MetroPolisRegular}) format('opentype');
  }
  @font-face {
    font-family: 'Metropolis-medium';
    src: url(${MetroPolisMedium}) format('opentype');
  }
  @font-face {
    font-family: 'Metropolis-semibold';
    src: url(${MetroPolisSemiBold}) format('opentype');
  }
  @font-face {
    font-family: 'Metropolis-bold';
    src: url(${MetroPolisBold}) format('opentype');
  }
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
  }
  body, html, #root{
    height:100%;
  }
  a {
    text-decoration: none;
    color: #2E2E2E;
  }
`;

export default GlobalStyle;
