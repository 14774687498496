import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Image } from 'rebass';
import { Heading1611 } from '../heading/types';
import { Body2SemiBold } from '../typography/weights';
import StarRating from '../rating/rating';
import theme from '../../theme';

const { spacing } = theme;

const Card = styled(Flex)`
  border-radius: 6px;
  border: 1px solid rgb(237, 237, 237);
  box-shadow: 0px 4px 16px 0px rgba(223, 223, 223, 0.15);
  overflow: hidden;
  min-width: 214px;

  img {
    height: 142px;
  }
`;

function PropertyCard({ headingText, subHeadingText, ratingValue, imgSrc }) {
  return (
    <Card
      flexDirection="column"
      bg={theme.applicationColors.fillColors.solidWhite}
      mr={spacing['1x']}
    >
      <Image src={imgSrc} width="100%" />
      <Flex px={spacing['2x']} pb={[spacing['2x']]} flexDirection="column">
        <Flex mt={spacing['2x']} mb={spacing['2x']}>
          <Heading1611
            ellipsize
            headingText={headingText}
            subHeadingText={subHeadingText}
          />
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <StarRating disable ratingValue={ratingValue} />
          <Flex ml={spacing['1x']}>
            <Body2SemiBold>{ratingValue}</Body2SemiBold>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

PropertyCard.propTypes = {
  headingText: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default PropertyCard;
