import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body1Medium } from '../typography/weights';

const navigatorContext = React.createContext();

export { navigatorContext };

const HFlex = styled(Flex)`
  height: ${p => p.h};
  position: ${p => p.pos};
  pointer-events: ${p => p.pointerEvents};
`;

function BaseNavigator({
  heading,
  leftComponent,
  rightComponent1,
  rightComponent2,
  children,
}) {
  const [navigator, showNavigator] = React.useState(false);
  const [headingText, setHeading] = React.useState(heading);
  const [RenderLeft, setRenderLeft] = React.useState(leftComponent);
  const [RenderRight1, setRenderRight1] = React.useState(rightComponent1);
  const [RenderRight2, setRenderRight2] = React.useState(rightComponent2);

  return (
    <navigatorContext.Provider
      value={{
        showNavigator,
        setHeading,
        setRenderLeft,
        setRenderRight1,
        setRenderRight2,
      }}
    >
      {navigator ? (
        <>
          <HFlex
            width="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            p="2px 12px"
            mt="8px"
            h="48px"
          >
            {RenderLeft}
            <HFlex
              width="100%"
              justifyContent="center"
              alignItems="center"
              pos="absolute"
              h="44px"
              pointerEvents="none"
            >
              <Body1Medium>{headingText}</Body1Medium>
            </HFlex>
            <HFlex width="100%" />
            {RenderRight1}
            <HFlex width="12px" />
            {RenderRight2}
          </HFlex>
          {children}
        </>
      ) : (
        children
      )}
    </navigatorContext.Provider>
  );
}

BaseNavigator.propTypes = {
  heading: PropTypes.string.isRequired,
  leftComponent: PropTypes.node.isRequired,
  rightComponent1: PropTypes.node.isRequired,
  rightComponent2: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(BaseNavigator);
