import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Button from '../base/button';

const constants = {
  bg: theme.applicationColors.fillColors.applicationPrimary,
  border: `1px solid ${theme.applicationColors.outlineColors.outlineSecondary}`,
  color: '#fff',
  activeColor: theme.applicationColors.fillColors.applicationPrimaryDark,
};

function Primary({ width, height, onClick, fontSize, disabled, children }) {
  return (
    <Button
      width={width}
      height={height}
      fontSize={fontSize}
      disabled={disabled}
      onClick={onClick}
      {...constants}
    >
      {children}
    </Button>
  );
}

Primary.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  fontSize: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Primary;
