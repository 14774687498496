/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../theme';

const Toggle = styled.div`
  &:focus {
    outline: none;
  }
  .tgl {
    display: none;
    &:after,
    &:before,
    & + .tgl-btn {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }

    + .tgl-btn {
      outline: none;
      width: 45px;
      height: 27px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      background: ${theme.applicationColors.fillColors.greySupporter};
      border-radius: 18px;
      padding: 1px;
      transition: all 0.4s ease;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: '';
        width: 23px;
        height: 23px;
      }

      &:after {
        left: 1px;
        border-radius: 50%;
        background: ${theme.applicationColors.fillColors.solidWhite};
        transition: all 0.2s ease;
      }

      &:before {
        display: none;
      }
    }
    &:checked + .tgl-btn {
      background: ${theme.applicationColors.fillColors.applicationPrimary};
      border: 1px solid
        ${theme.applicationColors.outlineColors.outlineSecondary};
      padding: 1px;
    }
    &:checked + .tgl-btn:after {
      left: 44%;
    }
  }
`;

function BaseToggle({ onChange }) {
  return (
    <Toggle>
      <input className="tgl" id="toggle" type="checkbox" onChange={onChange} />
      <label className="tgl-btn" htmlFor="toggle" />
    </Toggle>
  );
}

BaseToggle.propTypes = {
  onChange: PropTypes.func,
};

BaseToggle.defaultProps = {
  onChange: () => {},
};

export default BaseToggle;
