import withSize from './withSize';
import theme from '../../../theme';

export const H1 = withSize(
  theme.typography.sizes.h1,
  theme.typography.lineHeight.h1,
);
export const H2 = withSize(
  theme.typography.sizes.h2,
  theme.typography.lineHeight.h2,
);
export const Body1 = withSize(
  theme.typography.sizes.body1,
  theme.typography.lineHeight.body1,
);
export const Body2 = withSize(
  theme.typography.sizes.body2,
  theme.typography.lineHeight.body2,
);
export const Subtitle = withSize(
  theme.typography.sizes.subtitle,
  theme.typography.lineHeight.subtitle,
);
export const Caption = withSize(
  theme.typography.sizes.caption,
  theme.typography.lineHeight.caption,
);
export const Overline = withSize(
  theme.typography.sizes.overline,
  theme.typography.lineHeight.overline,
);
export const Mini = withSize(
  theme.typography.sizes.mini,
  theme.typography.lineHeight.mini,
);
