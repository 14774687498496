import React from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SmallStarEmpty } from '../../icons/smallStarEmpty.svg';
import { ReactComponent as SmallStarFull } from '../../icons/smallStarFull.svg';
import { ReactComponent as LargeStarEmpty } from '../../icons/largeStarEmpty.svg';
import { ReactComponent as LargeStarFull } from '../../icons/largeStarFull.svg';

const CRating = styled(Rating)`
  & > span {
    margin: 0px ${p => (!p.readonly ? '8px' : '2px')};
  }
`;

function StarRating({ ratingValue, disable }) {
  if (disable) {
    return (
      <CRating
        fractions={2}
        className="star"
        readonly={disable}
        initialRating={ratingValue}
        emptySymbol={<SmallStarEmpty />}
        fullSymbol={<SmallStarFull />}
      />
    );
  }
  return (
    <CRating
      initialRating={ratingValue}
      className="star"
      emptySymbol={<LargeStarEmpty />}
      fullSymbol={<LargeStarFull />}
    />
  );
}

StarRating.propTypes = {
  ratingValue: PropTypes.number.isRequired,
  disable: PropTypes.bool,
};

StarRating.defaultProps = {
  disable: false,
};

export default StarRating;
