/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

import {
  SubtitleMedium,
  Body2Regular,
  Body1Medium,
  Body2SemiBold,
} from '../components/typography/weights';
import theme from '../theme';
import { navigatorContext } from '../components/navigator';
import { ReactComponent as BackIcon } from '../icons/back.svg';

const Content = styled(Flex)`
  color: ${theme.applicationColors.textColors.textTertiary};

  div {
    line-height: 25px;
  }

  button {
    padding-top: 0px !important;
  }
`;

// eslint-disable-next-line react/prop-types
const AboutDemo = ({ history }) => {
  const {
    showNavigator,
    setRenderLeft,
    setRenderRight1,
    setRenderRight2,
    setHeading,
  } = React.useContext(navigatorContext);

  const handleBackClick = () => {
    history.goBack();
  };

  React.useEffect(() => {
    showNavigator(true);
    setHeading('');
    setRenderLeft(
      <Flex p="10px" width="44px" onClick={handleBackClick}>
        <BackIcon />
      </Flex>,
    );
    setRenderRight2(null);
    setRenderRight1(null);
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex mt="2px" px="24px" py="16px">
        <SubtitleMedium>About</SubtitleMedium>
      </Flex>
      <Content mx="24px" mt="8px">
        <Body2Regular>
          Located just 2.2 km from Mall Road, this is an offering of a private
          room in a guest house that can accommodate two guests. The property is
          also in proximity to numerous restaurants such as Dylan's Toasted &
          Roasted Coffee House (400 m), Casa Bella Vista (550 m), and Lazy Dog
          Lounge (550 m).
        </Body2Regular>
      </Content>
      <Flex mt="16px" px="24px" py="16px">
        <Body1Medium>The Space</Body1Medium>
      </Flex>
      <Content mx="24px" mt="8px">
        <Body2Regular>
          Surrounded by lush green Himalayas, this property is a perfect retreat
          for a couple looking to stay in the heart of the city. The
          accommodation boasts an ample parking space, thereby making parking
          easier for those on a road trip. The garden area is characterised by
          flowering plants and equipped with easy chairs, which makes for an
          ideal spot to indulge in pleasant conversations. The seating area is
          well-appointed and houses a beverages counter. The bedroom is spacious
          and furnished well with a comfy bed, table lamps, a dresser, a wooden
          cabinet, a television for entertainment, and a coffee seating. The
          eye-catching pattern on the sea blue wall adds an interesting
          dimension to the room. The attached bathroom is well tiled and
          equipped with modern sanitary ware.
        </Body2Regular>
      </Content>
      <Flex mt="16px" px="24px" py="16px">
        <Body1Medium>Local Experience</Body1Medium>
      </Flex>
      <Content mx="24px" mt="8px" mb="31px">
        <Body2Regular>
          <Body2SemiBold>Points of Interest :</Body2SemiBold>
          <br /> Mall road- 2kms
          <br /> Solang Valley - 14.8 Km
          <br /> Hidimba Devi Temple - 2 kms
          <br /> Rohtang Pass - 52.5 Km
          <br /> Bhrigu Lake - 19.5 Km
          <br /> Manu Temple - 1 Km
          <br /> Beas Kund - 22.4 Km
          <br /> Manikaran - 82.1 Km
          <br />
          Pandoh Dam - 90.4 Km
        </Body2Regular>
      </Content>
    </Flex>
  );
};

export default AboutDemo;
