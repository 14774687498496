import React from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CaptionMedium } from '../typography/weights';

import theme from '../../theme';

const { spacing } = theme;

const Amenity = styled(Flex)`
  border: 1px solid rgb(237, 237, 237);
  border-radius: 8px;
`;

function PropertyCard({ text, imgSrc }) {
  return (
    <Flex flexDirection="row" alignItems="center">
      <Amenity
        width="48px"
        bg="#fff"
        alignItems="center"
        justifyContent="center"
        py="15px"
      >
        <Flex>{imgSrc}</Flex>
      </Amenity>
      <Flex ml={spacing['1x']}>
        <CaptionMedium>{text}</CaptionMedium>
      </Flex>
    </Flex>
  );
}

PropertyCard.propTypes = {
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.node.isRequired,
};

export default PropertyCard;
