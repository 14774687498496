import React from 'react';
import { Flex } from 'rebass';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { px } from '../theme';
import { Body1Medium } from '../components/typography/weights';
import { ReactComponent as Logo } from '../icons/logo.svg';
import { navigatorContext } from '../components/navigator';
import { ReactComponent as BackIcon } from '../icons/back.svg';

const HFlex = styled(Flex)`
  height: 100%;
`;

// eslint-disable-next-line react/prop-types
function HomePage({ history }) {
  const {
    showNavigator,
    setRenderLeft,
    setRenderRight1,
    setRenderRight2,
  } = React.useContext(navigatorContext);

  React.useEffect(() => {
    setRenderLeft(
      <Flex
        p="10px"
        width="44px"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
      </Flex>,
    );
    setRenderRight1(null);
    setRenderRight2(null);
    showNavigator(false);
  }, []);

  return (
    <HFlex flexDirection="column" alignItems="center" bg="#FAFAFA">
      <Flex mt={px(52)}>
        <Logo />
      </Flex>
      <Flex flex="row" flexWrap="wrap" mt={px(36)} width={px(324)}>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/controls">
            <Body1Medium>Controls</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/search">
            <Body1Medium>Search</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/typography">
            <Body1Medium>Typography</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/tags">
            <Body1Medium>Tags</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/basiclayout">
            <Body1Medium>Basic Layout</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/textfield">
            <Body1Medium>Text Fields</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/messages">
            <Body1Medium>Messages</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/headings">
            <Body1Medium>Headings</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/singleline">
            <Body1Medium>Single Line Text</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/poc">
            <Body1Medium>Responsive POC</Body1Medium>
          </Link>
        </Flex>
        <Flex
          bg="#fff"
          p={`${px(25)} ${px(0)}`}
          width={px(152)}
          m={px(5)}
          justifyContent="center"
        >
          <Link to="/propertydemo">
            <Body1Medium>Property Demo</Body1Medium>
          </Link>
        </Flex>
      </Flex>
    </HFlex>
  );
}

export default HomePage;
