// eslint-disable-next-line no-undef
const isMobile = window.screen.width < 700;

const gUnit = isMobile ? 1 : 1.2;

const customPx = unit => val => `${Math.round(unit * val)}px`;

export const px = customPx(gUnit);

const colors = {
  tealGreenDark: '#029A75',
  tealGreen: '#00BA8C',
  berryRed: '#F54A4A',
  berryRedDark: '#E53636',
  mossGreen: '#93C597',
  mildYellow: '#F4DD6E',
  blue: '#2A4469',
  g100: '#FFFFFF',
  g200: '#FAFAFA',
  g300: '#EDEDED',
  g400: '#DFDFDF',
  g500: '#BBBBBB',
  g600: '#A6A6A6',
  g700: '#5E5E5E',
  g800: '#2E2E2E',
  g900: '#0D0D0D',
};

const customUnits = {
  typoGraphy: customPx(isMobile ? 1 : 1.2),
  buttons: customPx(isMobile ? 1 : 1.14),
  tags: customPx(gUnit),
};

const themeFactory = units => ({
  base: {
    fontSize: px(16),
  },
  spacing: {
    '0.5x': px(4),
    '1x': px(8),
    '2x': px(16),
    '3x': px(24),
    '4x': px(32),
    '5x': px(40),
    '6x': px(48),
  },
  applicationColors: {
    textColors: {
      textPrimary: colors.g900,
      textSecondary: colors.g800,
      textTertiary: colors.g700,
      textSupporter: colors.g600,
      textPlaceHolder: colors.g500,
      textDisabled: colors.g400,
      textRed: colors.berryRed,
      textGreen: colors.tealGreen,
      textWhite: colors.g100,
    },
    outlineColors: {
      outlinePrimary: colors.g300,
      outlineSecondary: colors.tealGreenDark,
      outlineTertiary: colors.berryRedDark,
    },
    dividerColors: {
      dividerPrimary: colors.g300,
      dividerPrimaryDark: colors.g500,
      dividerSecondary: colors.tealGreen,
      dividerTertiary: colors.berryRed,
    },
    fillColors: {
      applicationPrimary: colors.tealGreen,
      applicationPrimaryDark: colors.tealGreenDark,
      applicationDestructive: colors.berryRed,
      applicationDestructiveDark: colors.berryRedDark,
      solidWhite: colors.g100,
      greySupporter: colors.g400,
      tagYellow: colors.mildYellow,
      properlyBlue: colors.blue,
    },
  },
  avatar: {
    size: px(44),
  },
  buttons: {
    small: {
      height: units.buttons(32),
      px: units.buttons,
    },
    large: {
      height: units.buttons(42),
      px: units.buttons,
    },
  },
  tags: {
    px: units.tags,
    height: units.tags(21),
    borderRadius: '2px 2px 2px 0px',
    padding: `${units.tags(4)} ${units.tags(8)} ${units.tags(4)} ${units.tags(
      8,
    )}`,
    position: 'relative',
  },
  typography: {
    px: units.typoGraphy,
    sizes: {
      h1: units.typoGraphy(24),
      h2: units.typoGraphy(21),
      subtitle: units.typoGraphy(18),
      body1: units.typoGraphy(16),
      body2: units.typoGraphy(14),
      caption: units.typoGraphy(12),
      mini: units.typoGraphy(11),
      overline: units.typoGraphy(10),
    },
    lineHeight: {
      h1: units.typoGraphy(33),
      h2: units.typoGraphy(29),
      subtitle: units.typoGraphy(25),
      body1: units.typoGraphy(24),
      body2: units.typoGraphy(20),
      caption: units.typoGraphy(16),
      mini: units.typoGraphy(14),
      overline: units.typoGraphy(13),
    },
    weights: {
      regular: 'Metropolis-regular',
      medium: 'Metropolis-medium',
      semiBold: 'Metropolis-semibold',
    },
  },
});

export default themeFactory(customUnits);
