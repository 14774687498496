import React from 'react';
import { Flex } from 'rebass';
import { navigatorContext } from '../components/navigator';
import SearchField from '../components/search/index';
import FadeSearch from '../components/search/fadesearch';

const SearchFieldPage = () => {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Search Fields');
  }, []);

  return (
    <Flex flexDirection="column" m="10px 24px">
      <SearchField value="" />
      <Flex mt="20px">
        <FadeSearch value="" />
      </Flex>
    </Flex>
  );
};

export default SearchFieldPage;
