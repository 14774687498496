import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Button from '../base/button';

const constants = {
  bg: theme.applicationColors.fillColors.applicationDestructive,
  border: `1px solid ${
    theme.applicationColors.fillColors.applicationDestructiveDark
  }`,
  color: '#fff',
  activeColor: theme.applicationColors.fillColors.applicationDestructiveDark,
};

function Destructive({ width, height, onClick, fontSize, disabled, children }) {
  return (
    <Button
      width={width}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      {...constants}
    >
      {children}
    </Button>
  );
}

Destructive.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fontSize: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Destructive;
