import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { navigatorContext } from '../components/navigator';
import theme from '../theme';

const HFlex = styled(Flex)`
  height: 100%;
`;

function BasicLayoutPage() {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Basic Layout');
  }, []);

  return (
    <HFlex
      width="100%"
      p={`0px ${theme.spacing['3x']}`}
      bg={theme.applicationColors.fillColors.applicationDestructive}
      flexDirection="column"
    >
      <HFlex bg={theme.applicationColors.fillColors.solidWhite} width="100%" />
    </HFlex>
  );
}

export default BasicLayoutPage;
