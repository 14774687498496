import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from '../base';
import {
  SubtitleMedium,
  Body2Medium,
  Body1Medium,
  CaptionSemiBold,
  OverlineSemiBold,
  H2Regular,
  MiniMedium,
} from '../../typography/weights';
import theme from '../../../theme';

const Ellipsize = styled.div`
  width: 100%;

  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const createHeading = (HeadingT, SubHeadingT, MiniTextT, isNaked) => {
  function Child({ headingText, subHeadingText, miniText, ellipsize }) {
    return (
      <Heading
        HeadingT={
          ellipsize ? (
            <Ellipsize>
              <HeadingT>{headingText}</HeadingT>
            </Ellipsize>
          ) : (
            <HeadingT>{headingText}</HeadingT>
          )
        }
        naked={!isNaked}
        SubHeadingT={
          subHeadingText && (
            <SubHeadingT
              color={theme.applicationColors.textColors.textSupporter}
            >
              {subHeadingText}
            </SubHeadingT>
          )
        }
        MiniTextT={
          miniText && (
            <MiniTextT color={theme.applicationColors.textColors.textSupporter}>
              {miniText}
            </MiniTextT>
          )
        }
      />
    );
  }

  Child.propTypes = {
    headingText: PropTypes.node.isRequired,
    subHeadingText: PropTypes.string,
    miniText: PropTypes.string,
    ellipsize: PropTypes.bool,
  };

  Child.defaultProps = {
    subHeadingText: null,
    miniText: null,
    ellipsize: false,
  };

  return Child;
};

// exports
const Heading18 = createHeading(SubtitleMedium, Body2Medium, CaptionSemiBold);
export const HeadingRegular = createHeading(
  H2Regular,
  Body2Medium,
  CaptionSemiBold,
  true,
);
export const HeadingBodyMedium = createHeading(
  Body1Medium,
  OverlineSemiBold,
  CaptionSemiBold,
  true,
);

const InvertedHeadingText = props => (
  <Body2Medium
    {...props}
    color={theme.applicationColors.textColors.textSupporter}
  />
);
const InvertedSubHeadingText = props => (
  <Body1Medium
    {...props}
    color={theme.applicationColors.textColors.textPrimary}
  />
);
export const InvertedHeading = createHeading(
  InvertedHeadingText,
  InvertedSubHeadingText,
  CaptionSemiBold,
  true,
);

export const Heading1614 = createHeading(
  Body1Medium,
  Body2Medium,
  CaptionSemiBold,
  true,
);

export const Heading1611 = createHeading(
  Body1Medium,
  MiniMedium,
  CaptionSemiBold,
  true,
);

export default Heading18;
