import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import SingleLineBase from './base';
import {
  Body1Regular,
  Body1Medium,
  MiniSemiBold,
  CaptionMedium,
  SubtitleMedium,
} from '../typography/weights';
import theme from '../../theme';

const SingleLineTextFactory = (HeadingT, SubtitleT, MiniT) => {
  function Child({
    leftIcon,
    headingText,
    subtitleText,
    miniText,
    rightIcon,
    borderColor,
  }) {
    return (
      <SingleLineBase
        LeftComponent={
          <Flex flex="row" alignItems="center">
            {leftIcon && <Flex mr="8px">{leftIcon}</Flex>}
            <HeadingT>{headingText}</HeadingT>
          </Flex>
        }
        SubComponent={
          <Flex ml={leftIcon ? '32px' : 0}>
            <SubtitleT color={theme.applicationColors.textColors.textSupporter}>
              {subtitleText}
            </SubtitleT>
          </Flex>
        }
        RightComponent={
          <Flex flex="row" alignItems="center">
            <MiniT color={theme.applicationColors.textColors.textSupporter}>
              {miniText}
            </MiniT>
            {rightIcon && <Flex mr="8px">{rightIcon}</Flex>}
          </Flex>
        }
        borderColor={borderColor}
      />
    );
  }

  Child.propTypes = {
    leftIcon: PropTypes.node,
    headingText: PropTypes.string.isRequired,
    subtitleText: PropTypes.string,
    miniText: PropTypes.string,
    rightIcon: PropTypes.node,
    borderColor: PropTypes.string,
  };

  Child.defaultProps = {
    leftIcon: null,
    subtitleText: null,
    miniText: null,
    rightIcon: null,
    borderColor: theme.applicationColors.dividerColors.dividerPrimary,
  };

  return Child;
};

const SingleLine16 = SingleLineTextFactory(
  Body1Regular,
  MiniSemiBold,
  MiniSemiBold,
);

export const SingleLine1612 = SingleLineTextFactory(
  Body1Medium,
  CaptionMedium,
  MiniSemiBold,
);

export const SingleLine1812 = SingleLineTextFactory(
  SubtitleMedium,
  CaptionMedium,
  MiniSemiBold,
);

export default SingleLine16;
