import React from 'react';
import PropTypes from 'prop-types';
import MessageBase from '../base/message';

function MessageBtn({ message, svg, bg, color }) {
  return (
    <MessageBase message={message} RightComp={svg} bg={bg} color={color} />
  );
}

MessageBtn.propTypes = {
  message: PropTypes.string.isRequired,
  svg: PropTypes.node,
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

MessageBtn.defaultProps = {
  svg: null,
};

export default MessageBtn;
