import {
  Primary,
  Secondary,
  Destructive,
  Plain,
  TextBlack,
  TextWhite,
} from '../types';
import HOC from './hoc';
import theme from '../../../theme';
import { Body2SemiBold } from '../../typography/weights';

const LargeTheme = theme.buttons.large;

export const PrimaryLarge = HOC(Primary, LargeTheme, Body2SemiBold);
export const SecondaryLarge = HOC(Secondary, LargeTheme, Body2SemiBold);
export const DestructiveLarge = HOC(Destructive, LargeTheme, Body2SemiBold);
export const PlainLarge = HOC(Plain, LargeTheme, Body2SemiBold);
export const TextBlackLarge = HOC(TextBlack, LargeTheme, Body2SemiBold);
export const TextWhiteLarge = HOC(TextWhite, LargeTheme, Body2SemiBold);
