import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Core from './core';

const root = document.getElementById('root'); // eslint-disable-line no-undef

ReactDOM.render(<Core />, root);

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept('./core/index', () => {
    import('./core/index').then(module => {
      const NextApp = module.default;
      ReactDOM.render(<NextApp />, root);
    });
  });
}
