import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Button from '../base/button';

const constants = {
  bg: theme.applicationColors.fillColors.solidWhite,
  color: theme.applicationColors.textColors.textPrimary,
  activeColor: theme.applicationColors.fillColors.greySupporter,
};

function Plain({ width, height, onClick, fontSize, disabled, children }) {
  return (
    <Button
      width={width}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      {...constants}
    >
      {children}
    </Button>
  );
}

Plain.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  fontSize: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Plain;
