import {
  Primary,
  Secondary,
  Destructive,
  Plain,
  TextBlack,
  TextWhite,
  TextGreen,
} from '../types';
import HOC from './hoc';
import theme from '../../../theme';
import { CaptionSemiBold } from '../../typography/weights';

const SmallTheme = theme.buttons.small;

export const PrimarySmall = HOC(Primary, SmallTheme, CaptionSemiBold);
export const SecondarySmall = HOC(Secondary, SmallTheme, CaptionSemiBold);
export const DestructiveSmall = HOC(Destructive, SmallTheme, CaptionSemiBold);
export const PlainSmall = HOC(Plain, SmallTheme, CaptionSemiBold);
export const TextBlackSmall = HOC(TextBlack, SmallTheme, CaptionSemiBold);
export const TextWhiteSmall = HOC(TextWhite, SmallTheme, CaptionSemiBold);
export const TextGreenSmall = HOC(TextGreen, SmallTheme, CaptionSemiBold);
