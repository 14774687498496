import React from 'react';
import { Flex } from 'rebass';

import { navigatorContext } from '../components/navigator';
import SingleLine16 from '../components/singlelinetext';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import Heading18 from '../components/heading/types';
import {
  PrimaryLarge,
  SecondaryLarge,
} from '../components/buttons/sizes/large';

const POCPage = () => {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Responsive POC');
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" mt="20px">
      <Heading18
        headingText="Some of our best properties"
        subHeadingText="You cant miss this"
      />
      <SingleLine16
        leftIcon={<HeartIcon />}
        headingText="Some of our best properties"
        miniText="Mini 11px"
      />
      <SingleLine16
        leftIcon={<HeartIcon />}
        headingText="Some of our best properties"
        miniText="Mini 11px"
        subtitleText="Sub Text"
      />
      <Flex
        flexDirection="row"
        mt="20px"
        justifyContent="space-around"
        width="100%"
      >
        <PrimaryLarge width={100}>Button</PrimaryLarge>
        <SecondaryLarge width={100}>Button</SecondaryLarge>
      </Flex>
    </Flex>
  );
};

export default POCPage;
