import React from 'react';
import PropTypes from 'prop-types';
import MessageBtn from '../types/messageBtn';
import MessageIcon from '../types/messageIcon';
import theme from '../../../theme';

const constants = {
  bg: theme.applicationColors.fillColors.greySupporter,
  color: theme.applicationColors.textColors.textPrimary,
};

export function MutedMessageBtn({ message, btnText }) {
  return (
    <MessageBtn message={message} btnText={btnText} {...constants} btBlack />
  );
}

export function MutedMessageIcon({ message, svg }) {
  return <MessageIcon message={message} svg={svg} {...constants} />;
}

MutedMessageBtn.propTypes = {
  message: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};
MutedMessageBtn.defaultProps = {
  btnText: null,
};

MutedMessageIcon.propTypes = {
  message: PropTypes.string.isRequired,
  svg: PropTypes.node,
};
MutedMessageIcon.defaultProps = {
  svg: null,
};
