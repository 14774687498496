import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../theme';
import Button from '../base/button';

const constants = {
  bg: 'transparent',
  color: theme.applicationColors.textColors.textWhite,
};

function Text({ fontSize, onClick, disabled, children }) {
  return (
    <Button
      fontSize={fontSize}
      onClick={onClick}
      disabled={disabled}
      px="0px"
      py="8px"
      {...constants}
    >
      {children}
    </Button>
  );
}

Text.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fontSize: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Text;
