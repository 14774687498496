import React from 'react';
import { Flex } from 'rebass';

import { navigatorContext } from '../components/navigator';
import Heading18 from '../components/heading/types';

const HeadingPage = () => {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Headings');
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" mt="20px">
      <Heading18 headingText="Some of our best properties" />
      <Heading18
        headingText="Some of our best properties"
        subHeadingText="You cant miss this"
      />
      <Heading18
        headingText="Some of our best properties"
        subHeadingText="You cant miss this"
        miniText="Mini Text"
      />
    </Flex>
  );
};

export default HeadingPage;
