/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Flex } from 'rebass';

import { SubtitleMedium } from '../components/typography/weights';
// import theme from '../theme';
import AmenityCard from '../components/amenitycard';
import { navigatorContext } from '../components/navigator';
import { ReactComponent as BackIcon } from '../icons/back.svg';
import { ReactComponent as PetIcon } from '../icons/pet.svg';
import { ReactComponent as BellBoyIcon } from '../icons/bellboy.svg';
import { ReactComponent as CareTaker } from '../icons/caretaker.svg';
import { ReactComponent as Family } from '../icons/family.svg';
import { ReactComponent as Power } from '../icons/power.svg';
import { ReactComponent as DoctorIcon } from '../icons/doctor.svg';
import { ReactComponent as HotWater } from '../icons/hotwater.svg';

// eslint-disable-next-line react/prop-types
const AmenitiesDemo = ({ history }) => {
  const {
    showNavigator,
    setRenderLeft,
    setRenderRight1,
    setRenderRight2,
    setHeading,
  } = React.useContext(navigatorContext);

  const handleBackClick = () => {
    history.goBack();
  };

  React.useEffect(() => {
    showNavigator(true);
    setHeading('');
    setRenderLeft(
      <Flex p="10px" width="44px" onClick={handleBackClick}>
        <BackIcon />
      </Flex>,
    );
    setRenderRight2(null);
    setRenderRight1(null);
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex mt="2px" px="24px" py="16px">
        <SubtitleMedium>Amenities</SubtitleMedium>
      </Flex>
      <Flex mt="8px" flexDirection="column" ml="24px">
        <Flex mb="16px">
          <AmenityCard text="Pet Care" imgSrc={<PetIcon />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Bell Boy" imgSrc={<BellBoyIcon />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Family" imgSrc={<Family />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Care Taker" imgSrc={<CareTaker />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Power Backup" imgSrc={<Power />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Doctor on call" imgSrc={<DoctorIcon />} />
        </Flex>
        <Flex mb="16px">
          <AmenityCard text="Hot Water" imgSrc={<HotWater />} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AmenitiesDemo;
