import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Heading from '../../heading/base';

const BorderBottom = styled(Flex)`
  border-bottom: 1px solid ${p => p.c};
`;

function SingleLineBase({
  LeftComponent,
  SubComponent,
  RightComponent,
  borderColor,
}) {
  return (
    <BorderBottom c={borderColor} width="100%">
      <Heading
        naked
        HeadingT={LeftComponent}
        SubHeadingT={SubComponent}
        MiniTextT={RightComponent}
      />
    </BorderBottom>
  );
}

SingleLineBase.propTypes = {
  LeftComponent: PropTypes.node,
  SubComponent: PropTypes.node,
  RightComponent: PropTypes.node,
  borderColor: PropTypes.string.isRequired,
};

SingleLineBase.defaultProps = {
  LeftComponent: null,
  SubComponent: null,
  RightComponent: null,
};

export default SingleLineBase;
