import { Button } from 'rebass';
import styled from 'styled-components';
import theme from '../../../theme';

const ButtonWithHeight = styled(Button)`
  height: ${props => props.height};
  cursor: ${props => props.disabled && 'not-allowed'};
  border: ${props => props.disabled && 'none'};
  color: ${props =>
    props.disabled && theme.applicationColors.textColors.textWhite};
  background: ${props =>
    props.disabled && theme.applicationColors.fillColors.greySupporter};
  border-radius: 4px;
  &:active {
    background: ${props =>
      props.disabled
        ? theme.applicationColors.fillColors.greySupporter
        : props.activeColor};
  }
  &:focus {
    outline: none;
  }
`;

export default ButtonWithHeight;
