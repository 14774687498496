import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { navigatorContext } from '../components/navigator';
import { px } from '../theme';
import Checkbox from '../components/checkbox';
import BaseToggle from '../components/toggle';
import {
  PrimarySmall,
  SecondarySmall,
  DestructiveSmall,
  PlainSmall,
  TextBlackSmall,
  TextWhiteSmall,
} from '../components/buttons/sizes/small';
import {
  PrimaryLarge,
  SecondaryLarge,
  DestructiveLarge,
  PlainLarge,
  TextBlackLarge,
  TextWhiteLarge,
} from '../components/buttons/sizes/large';
import Icon from '../components/icons/icon';
import PriceSlider from '../components/slider';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import StarRating from '../components/rating/rating';

const HFlex = styled(Flex)`
  height: ${p => p.height};
`;

function ControlsPage() {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Controls');
  }, []);
  const [disabled, setDisabled] = React.useState(false);
  const toggle = () => setDisabled(prevState => !prevState);

  return (
    <Flex flexDirection="column" alignItems="center">
      <HFlex
        mt={px(50)}
        mb={px(50)}
        flexDirection="row"
        flexWrap="wrap"
        width={px(220)}
        justifyContent="space-around"
        alignItems="flex-start"
        height={px(150)}
      >
        <PrimarySmall disabled={disabled} width={100}>
          Button
        </PrimarySmall>
        <SecondarySmall disabled={disabled} width={100}>
          Button
        </SecondarySmall>
        <DestructiveSmall disabled={disabled} width={100}>
          Button
        </DestructiveSmall>
        <PlainSmall disabled={disabled} width={100}>
          Button
        </PlainSmall>
        <TextBlackSmall disabled={disabled} width={100}>
          Button
        </TextBlackSmall>
        <TextWhiteSmall disabled={disabled} width={100}>
          Button
        </TextWhiteSmall>
      </HFlex>
      <HFlex
        mb={px(50)}
        flexDirection="row"
        flexWrap="wrap"
        width={px(230)}
        justifyContent="space-around"
        alignItems="flex-start"
        height={px(150)}
      >
        <PrimaryLarge disabled={disabled} width={100}>
          Button
        </PrimaryLarge>
        <SecondaryLarge disabled={disabled} width={100}>
          Button
        </SecondaryLarge>
        <DestructiveLarge disabled={disabled} width={100}>
          Button
        </DestructiveLarge>
        <PlainLarge disabled={disabled} width={100}>
          Button
        </PlainLarge>
        <TextBlackLarge disabled={disabled} width={100}>
          Button
        </TextBlackLarge>
        <TextWhiteLarge disabled={disabled} width={100}>
          Button
        </TextWhiteLarge>
      </HFlex>
      <Flex mb="50px">
        <Checkbox onChange={e => toggle(e.target.value)} />
      </Flex>
      <Flex mb="50px">
        <BaseToggle />
      </Flex>
      <Flex mb="50px">
        <StarRating ratingValue="3" />
      </Flex>
      <Flex mb="50px">
        <StarRating ratingValue="2.53" disable />
      </Flex>
      <Flex mb="50px" width="100%">
        <PriceSlider min={100} max={7000} />
      </Flex>
      <Flex mb="50px">
        <Icon src={<HeartIcon />} tapable />
      </Flex>
      <Flex mb="50px">
        <Icon src={<HeartIcon />} />
      </Flex>
    </Flex>
  );
}

export default ControlsPage;
