import React from 'react';
import PropTypes from 'prop-types';

function HOC(Component, theme, TypoGraphy) {
  function Child({ width, onClick, disabled, children }) {
    return (
      <Component
        {...theme}
        width={theme.px(width)}
        onClick={onClick}
        disabled={disabled}
      >
        <TypoGraphy>{children}</TypoGraphy>
      </Component>
    );
  }
  Child.propTypes = {
    width: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
      .isRequired,
  };

  Child.defaultProps = {
    onClick: () => {},
  };

  return Child;
}

export default HOC;
