import React from 'react';
import { Flex } from 'rebass';
import { navigatorContext } from '../components/navigator';

import {
  ErrorMessageBtn,
  ErrorMessageIcon,
} from '../components/messages/finalMessage/errorMessage';
import {
  SuccessMessageBtn,
  SuccessMessageIcon,
} from '../components/messages/finalMessage/successMessage';
import {
  MutedMessageBtn,
  MutedMessageIcon,
} from '../components/messages/finalMessage/mutedMessage';
import Icon from '../components/icons/icon';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';

function MessagesPage() {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Messages');
  }, []);

  return (
    <Flex width="100%" flexDirection="column" mt="20px">
      <Flex mb="10px" width="100%">
        <ErrorMessageBtn message="Please enter a valid password for the last request and then try again later" />
      </Flex>
      <Flex mb="10px" width="100%">
        <ErrorMessageBtn
          message="Please enter a valid password for the last request and then try again later"
          btnText="Retry"
        />
      </Flex>
      <Flex mb="10px" width="100%">
        <ErrorMessageIcon
          message="Please enter a valid password for the last request and then try again later"
          svg={<Icon src={<HeartIcon />} tapable />}
        />
      </Flex>
      <Flex mb="10px" width="100%">
        <SuccessMessageBtn message="Coupon applied succesfullly" />
      </Flex>
      <Flex mb="10px" width="100%">
        <SuccessMessageBtn
          message="Coupon applied succesfullly"
          btnText="Button"
        />
      </Flex>
      <Flex mb="10px" width="100%">
        <SuccessMessageIcon
          message="Coupon applied succesfullly"
          svg={<Icon src={<HeartIcon />} tapable />}
        />
      </Flex>
      <Flex mb="10px" width="100%">
        <MutedMessageBtn message="Coupon applied succesfullly" />
      </Flex>
      <Flex mb="10px" width="100%">
        <MutedMessageBtn
          message="Coupon applied succesfullly"
          btnText="Button"
        />
      </Flex>
      <Flex mb="10px" width="100%">
        <MutedMessageIcon
          message="Coupon applied succesfullly"
          svg={<Icon src={<HeartIcon />} tapable />}
        />
      </Flex>
    </Flex>
  );
}

export default MessagesPage;
