import React from 'react';
import { Flex } from 'rebass';

import { navigatorContext } from '../components/navigator';

import RibbonPrimary from '../components/tags/type/primary';
import RibbonRed from '../components/tags/type/red';
import RibbonYellow from '../components/tags/type/yellow';

const TagsPage = () => {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Tags');
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" mt="20px">
      <Flex mb="10px">
        <RibbonPrimary>Book Now Pay Later</RibbonPrimary>
      </Flex>
      <Flex mb="10px">
        <RibbonRed>Get 10% off</RibbonRed>
      </Flex>
      <Flex mb="10px">
        <RibbonYellow>Early bird offer</RibbonYellow>
      </Flex>
    </Flex>
  );
};

export default TagsPage;
