import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { Flex } from 'rebass';

import GlobalStyle from '../theme/globals';
import Navigator from '../components/navigator/index';
import { ReactComponent as BackIcon } from '../icons/back.svg';

// Pages
import HomePage from '../pages/home';
import ControlsPage from '../pages/controls';
import TagsPage from '../pages/tags';
import SearchField from '../pages/search';
import TypoGraphyPage from '../pages/typography';
import BasicLayoutPage from '../pages/basiclayoutpage';
import TextFieldsPage from '../pages/textfieldpage';
import MessagesPage from '../pages/messagespage';
import HeadingPage from '../pages/headingpage';
import SingleLinePage from '../pages/singlelinepage';
import POCPage from '../pages/poc';
import PropertyDemo from '../pages/propertydemo';
import AboutDemo from '../pages/aboutdemo';
import AmenitiesDemo from '../pages/amenitiesdemo';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <TopNavigation>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/controls" component={ControlsPage} />
          <Route path="/typography" component={TypoGraphyPage} />
          <Route path="/tags" component={TagsPage} />
          <Route path="/basiclayout" component={BasicLayoutPage} />
          <Route path="/textfield" component={TextFieldsPage} />
          <Route path="/messages" component={MessagesPage} />
          <Route path="/headings" component={HeadingPage} />
          <Route path="/singleline" component={SingleLinePage} />
          <Route path="/poc" component={POCPage} />
          <Route path="/search" component={SearchField} />
          <Route path="/propertydemo" component={PropertyDemo} />
          <Route path="/about" component={AboutDemo} />
          <Route path="/aminities" component={AmenitiesDemo} />
        </Switch>
      </TopNavigation>
    </Router>
  );
}

const TopNavigation = withRouter(({ history, children }) => (
  <Navigator
    heading="Heading"
    leftComponent={
      <Flex
        p="10px"
        width="44px"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
      </Flex>
    }
  >
    {children}
  </Navigator>
));

export default App;
