import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import theme from '../../../theme';
import { OverlineSemiBold } from '../../typography/weights';

// Will remain constant
const constants = {
  height: theme.tags.height,
  borderRadius: theme.tags.borderRadius,
  padding: theme.tags.padding,
  position: theme.tags.position,
};

const Shimmer = keyframes`
  0% {transform:translateX(-100%);}
  100% {transform:translateX(100%);}
`;

const Container = styled.div`
  overflow-x: hidden;
  height: ${Number(constants.height.replace('px', '')) + 13}px;
`;

const ChildContainer = styled.div`
  position: relative;
  ::after {
    content: '';
    top: 0px;
    left: 0;
    transform: translateX(100%);
    width: 100%;
    height: ${Number(constants.height.replace('px', ''))}px;
    position: absolute;
    z-index: 1;
    animation: ${Shimmer} 1.5s infinite;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

const Ribbon = styled.span`
  background: ${props => props.background};
  color: ${props => props.color};
  height: ${constants.height};
  border-radius: ${p =>
    p.tailLess ? '2px 2px 2px 2px' : constants.borderRadius};
  padding: ${constants.padding};
  position: ${constants.position};
  display: block;
`;

const TailedRibbon = styled(Ribbon)`
  ::before {
    content: '';
    position: absolute;
    left: -23px;
    bottom: -13px;
    z-index: -1;
    border: 18px solid transparent;
    border-right-color: ${props => props.background};
  }
  ::after {
    content: '';
    position: absolute;
    left: -23px;
    bottom: -13px;
    z-index: -1;
    border: 18px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.4);
  }
`;

function RibbonContainer({ children, tailLess, ...props }) {
  return (
    <Container>
      <ChildContainer>
        {tailLess ? (
          <Ribbon tailLess={tailLess} {...props} {...constants}>
            <OverlineSemiBold>{children}</OverlineSemiBold>
          </Ribbon>
        ) : (
          <TailedRibbon {...props} {...constants}>
            <OverlineSemiBold>{children}</OverlineSemiBold>
          </TailedRibbon>
        )}
      </ChildContainer>
    </Container>
  );
}

RibbonContainer.propTypes = {
  children: PropTypes.node.isRequired,
  tailLess: PropTypes.bool,
};

RibbonContainer.defaultProps = {
  tailLess: false,
};

export default RibbonContainer;
