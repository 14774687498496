import React from 'react';
import PropTypes from 'prop-types';
import MessageBase from '../base/message';
import { TextWhiteSmall, TextBlackSmall } from '../../buttons/sizes/small';

function MessageBtn({ message, btnText, bg, color, btBlack }) {
  return (
    <MessageBase
      message={message}
      RightComp={
        btnText ? (
          btBlack ? (
            <TextBlackSmall>{btnText}</TextBlackSmall>
          ) : (
            <TextWhiteSmall>{btnText}</TextWhiteSmall>
          )
        ) : null
      }
      bg={bg}
      color={color}
    />
  );
}

MessageBtn.propTypes = {
  message: PropTypes.string.isRequired,
  btBlack: PropTypes.bool,
  btnText: PropTypes.string,
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

MessageBtn.defaultProps = {
  btnText: '',
  btBlack: false,
};

export default MessageBtn;
