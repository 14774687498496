import React from 'react';
import { Image, Flex } from 'rebass';
import PropTypes from 'prop-types';
import { HeadingBodyMedium } from '../heading/types';
// import { Body2SemiBold, CaptionMedium } from '../typography/weights';
import StarRating from '../rating/rating';
import theme from '../../theme';

const { spacing } = theme;

function PropertyCard({ headingText, subHeadingText, imgSrc, ratingValue }) {
  return (
    <Flex
      flexDirection="row"
      px={spacing['3x']}
      py={spacing['1x']}
      alignItems="center"
      width="100%"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Image
          src={imgSrc}
          borderRadius={50}
          width={theme.avatar.size}
          height={theme.avatar.size}
        />
        <Flex ml={spacing['1x']} mr={spacing['2x']}>
          <HeadingBodyMedium
            headingText={headingText}
            subHeadingText={subHeadingText}
          />
        </Flex>
      </Flex>
      <StarRating disable ratingValue={ratingValue} />
    </Flex>
  );
}

PropertyCard.propTypes = {
  headingText: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
};

export default PropertyCard;
