import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';

const BaseCheckbox = styled.label`
  display: block;
  width: 20px;
  height: 20px;
  position: relative;

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${theme.applicationColors.fillColors.greySupporter};
    border-radius: 3px;
  }

  .checkbox:checked ~ .checkbox-span {
    background-color: ${theme.applicationColors.fillColors.applicationPrimary};
    border: 1px solid ${theme.applicationColors.outlineColors.outlineSecondary};
  }

  .checkbox-span:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid ${theme.applicationColors.textColors.textWhite};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox:checked ~ .checkbox-span:after {
    display: block;
  }
`;

function Checkbox({ onChange }) {
  return (
    <BaseCheckbox>
      <input
        type="checkbox"
        id="checkbox"
        className="checkbox"
        onChange={onChange}
      />
      <span htmlFor="checkbox" className="checkbox-span" />
    </BaseCheckbox>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: () => {},
};

export default Checkbox;
