import React from 'react';
import { Flex } from 'rebass';

import { navigatorContext } from '../components/navigator';
import SingleLine16 from '../components/singlelinetext';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';

const SingleLinePage = () => {
  const { showNavigator, setHeading } = React.useContext(navigatorContext);
  React.useEffect(() => {
    showNavigator(true);
    setHeading('Single Line Text');
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" mt="20px">
      <SingleLine16 headingText="Some of our best properties" />
      <SingleLine16
        headingText="Some of our best properties"
        rightIcon={<HeartIcon />}
      />
      <SingleLine16
        headingText="Some of our best properties"
        miniText="Mini 11px"
      />
      <SingleLine16
        headingText="Some of our best properties"
        subtitleText="Sub Text 11"
        miniText="Mini 11px"
      />
      <SingleLine16
        leftIcon={<HeartIcon />}
        headingText="Some of our best properties"
        miniText="Mini 11px"
      />
      <SingleLine16
        leftIcon={<HeartIcon />}
        headingText="Some of our best properties"
        miniText="Mini 11px"
        subtitleText="Sub Text"
      />
    </Flex>
  );
};

export default SingleLinePage;
