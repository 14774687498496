import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HFlex = styled(Flex)`
  height: 44px;
  min-width: 44px;
`;

function Icon({ src, tapable }) {
  if (tapable) {
    return (
      <HFlex width="44px" alignItems="center" justifyContent="center">
        {src}
      </HFlex>
    );
  }
  return <Flex>{src}</Flex>;
}

Icon.propTypes = {
  src: PropTypes.node.isRequired,
  tapable: PropTypes.bool,
};

Icon.defaultProps = {
  tapable: false,
};

export default Icon;
